import { Card } from "antd";
import { ISupportMeterInfo } from "../overview-types";
import { formatCurrency } from "shared/utils";
import EditableValue from "components/editable-value";

interface RepeatedCollectionsWidgetProps {
    info: ISupportMeterInfo;
}

export default function RepeatedCollectionsWidget({ info }: RepeatedCollectionsWidgetProps) {


    return <Card bordered={false} title="Repeated Collections" style={{ marginTop: 5 }}>
        <table className="full-width bordered">
            <thead>
                <tr>
                    <th>Reason</th>
                    <th>Amount</th>
                    <th>Frequency</th>
                </tr>
            </thead>
            <tbody>
                {info.repeatedCollections?.map((collection, index) => (
                    <tr key={index}>
                        <td>
                            <EditableValue
                                prop={{
                                    id: collection.id,
                                    type: 'string',
                                    field: 'reason',
                                    value: collection.reason,
                                    name: 'Reason',
                                    entity: 'financials.repeated_collections'
                                }} />
                        </td>
                        {/* <td style={{ textAlign: 'right' }}>{formatCurrency(collection.requiredAmount)}</td> */}
                        <td style={{ textAlign: 'right' }}><EditableValue
                            displayFormatter={(v) => formatCurrency(+v)}
                            prop={{
                                id: collection.id,
                                type: 'number',
                                name: 'Amount',
                                entity: 'financials.repeated_collections',
                                field: 'amount',
                                value: formatCurrency(collection.requiredAmount)
                            }} /></td>
                        {/* <td>{formatCurrency(collection.requiredAmount)}</td> */}
                        <td>{collection.repeatType}</td>
                    </tr>
                ))}
                {info.repeatedCollections.length === 0 && <tr>
                    <td colSpan={3}><i>No repeated collections</i></td>
                </tr>}
            </tbody>
        </table>
    </Card>
}
