import { Form, Input, Radio, RadioChangeEvent, Space } from "antd";
import { IMeterInfo } from "./token-gen-info";

interface IMeterDetailsProps {
    info: IMeterInfo;
    onChange: (info: IMeterInfo) => void;
}

export default function MeterDetails(props: IMeterDetailsProps) {
    const onSubclassChange = (e: RadioChangeEvent) => {
        props.onChange({ ...props.info, subclass: e.target.value });
    };

    return (
        <>
            <Form.Item label="Type">
                <Radio.Group buttonStyle="solid" onChange={onSubclassChange}>
                    <Radio.Button value="0">Electricity</Radio.Button>
                    <Radio.Button value="1">Water</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Space
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
            >
                <Form.Item label="Meter Number">
                    <div className="subLabel">&nbsp;</div>
                    <Input
                        value={props.info.number}
                        onChange={(e) =>
                            props.onChange({
                                ...props.info,
                                number: e.target.value,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item label="SGC">
                    <div className="subLabel">Supply Group Code</div>
                    <Input
                        value={props.info.sgc}
                        onChange={(e) =>
                            props.onChange({
                                ...props.info,
                                sgc: e.target.value,
                            })
                        }
                    />
                </Form.Item>
            </Space>
            <Space
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
            >
                <Form.Item label="TI">
                    <div className="subLabel">Tariff Index</div>
                    <Input
                        value={props.info.ti}
                        onChange={(e) =>
                            props.onChange({
                                ...props.info,
                                ti: e.target.value,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item label="EA">
                    <div className="subLabel">Encryption</div>
                    <Input
                        value={props.info.ea}
                        onChange={(e) =>
                            props.onChange({
                                ...props.info,
                                ea: e.target.value,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item label="KRN">
                    <div className="subLabel">Key Revision</div>
                    <Input
                        value={props.info.krn}
                        onChange={(e) =>
                            props.onChange({
                                ...props.info,
                                krn: e.target.value,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item label="TCT">
                    <div className="subLabel">Carrier Type</div>
                    <Input
                        value={props.info.tct}
                        onChange={(e) =>
                            props.onChange({
                                ...props.info,
                                tct: e.target.value,
                            })
                        }
                    />
                </Form.Item>
            </Space>
        </>
    );
}
