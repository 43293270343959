import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Card, Col, Input, message, notification, Popconfirm, Row, Spin, Switch, Timeline } from "antd";
import { getEventDisplayName } from "./support-helpers";
import { toDisplayDate, toShortDate } from "shared/utils";
import MeterAccountUpdatedHistoryItem from "./overview-history-components/meter-account-updated";
import VendResult from "./overview-history-components/vend-result";
import KctGeneratedHistoryItem from "./overview-history-components/kct-generated";
import CollectionsPaidHistoryItem from "./overview-history-components/collections-paid";
import CollectionsCreatedHistoryItem from "./overview-history-components/collections-created";
import KctRequestedHistoryItem from "./overview-history-components/kct-requested";
import MeterCreatedHistoryItem from "./overview-history-components/meter-created";
import {
    generateEngToken,
    IEngMeterInfo
} from "./generate-tokens";
import Token, { IToken } from "components/token";
import EngTokenHistoryItem from "./overview-history-components/eng-token";
import StsCreditHistoryItem from "./overview-history-components/sts-credit";
import CallLogHistoryItem from "./overview-history-components/call-log-item";
import { EventLogEntry, ISupportMeterInfo } from "./overview-types";
import NoticesWidget from "./overview-widgets/notices-widget";
import RepeatedCollectionsWidget from "./overview-widgets/repeated-collections";
import PendingCollectionsWidget from "./overview-widgets/pending-collections";
import EditableValue from "components/editable-value";
import { CopyOutlined } from "@ant-design/icons";
import { fetchSecure } from "shared/net";
import IncidentMeterAddWidget from "./overview-widgets/incident-meter-add";

function getHistoryItemComponent(entry: EventLogEntry) {

    switch (entry.event) {
        case 'meters.account-updated':
            return <MeterAccountUpdatedHistoryItem entry={entry} />;
        case 'vending.vend-result':
            return <VendResult entry={entry} />;
        case 'collections.created':
            return <CollectionsCreatedHistoryItem entry={entry} />;
        case 'collections.paid':
            return <CollectionsPaidHistoryItem entry={entry} />;
        case 'meters.kct-generation':
            return <KctGeneratedHistoryItem entry={entry} />;
        case 'meters.kct-requested':
            return <KctRequestedHistoryItem entry={entry} />;
        case 'meters.created':
            return <MeterCreatedHistoryItem entry={entry} />;
        case 'sts.tokens.clear-tamper':
        case 'sts.tokens.clear-credit':
            return <EngTokenHistoryItem entry={entry} />;
        case 'sts.tokens.credit':
            return <StsCreditHistoryItem entry={entry} />;
        case 'support.call-logs':
            return <CallLogHistoryItem entry={entry} />;
    }


    return <tr>
        <td>{toDisplayDate(entry.entryDate)}</td>
        <td>{getEventDisplayName(entry.event)}</td>
        <td>
            <pre>No component available yet</pre>
        </td>
    </tr>;
}


export default function MeterOverviewPage() {

    let { id } = useParams();
    const [info, setInfo] = useState<ISupportMeterInfo>();
    const [vendsOnlyHistory, setVendsOnlyHistory] = useState<boolean>(true);
    const [generatedTokens, setGeneratedTokens] = useState<IToken[]>();
    const [api, contextHolder] = notification.useNotification();
    const [msgApi, msgContextHolder] = message.useMessage();


    function getMeterInfo(): IEngMeterInfo | undefined {
        if (!info) {
            return undefined;
        }
        return {
            number: info.meter.meterNumber,
            subclass: info.meter.subClass,
            sgc: info.meter.sgc,
            ea: info.meter.ea,
            krn: info.meter.krn,
            ti: info.meter.ti,
            tct: info.meter.tct,
        }
    }

    async function genTestToken() {
        const info = getMeterInfo()
        if (!info) {
            // TODO: Show error message
            return;
        }
        const token = await generateEngToken(info, 'test');
        setGeneratedTokens([{
            token: token,
            type: 'Test Token',
            units: '0.1',
        }]);
    }

    async function genClearCredit() {
        const info = getMeterInfo()
        if (!info) {
            return;
        }
        const token = await generateEngToken(info, 'credit');
        setGeneratedTokens([{
            token: token,
            type: 'Clear Credit',
            units: '0',
        }]);
    }

    async function genClearTamper() {
        const info = getMeterInfo()
        if (!info) {
            return;
        }
        const token = await generateEngToken(info, 'tamper');
        setGeneratedTokens([{
            token: token,
            type: 'Clear Tamper',
            units: '0',
        }]);
    }

    function loadMeterInfo(meterId: any) {
        fetchSecure(`/api/1/support/meters/${meterId}`)
            .then(response => response.json())
            .then(data => {
                setInfo(data);
            });
    }

    function refreshInfo() {
        loadMeterInfo(id);
    }

    function copySneakPeekUrl(info: any) {
        const txt = `https://ppex.us/?meter-number=${info.meter.meterNumber}`;
        navigator.clipboard.writeText(txt);
        api.success({
            message: 'Sneak Peek URL copied to clipboard',
            description: txt,
        });
    }

    function onActiveToggle(checked: boolean, entity: string, id: string) {
        fetchSecure('/api/1/in-place-update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                type: 'string',
                entity: entity,
                field: 'status',
                value: checked ? 'A' : 'I'
            })
        }).then(() => {
            refreshInfo();
            msgApi.success({
                content: 'Status updated',
            });
        }).catch(() => {
            msgApi.error({
                content: 'Status failed to update',
            });
        });
    }

    useEffect(() => {
        // Fetch meter details
        loadMeterInfo(id)
    }, [id]);

    return <>
        {contextHolder}{msgContextHolder}
        {!info ? <div style={{ textAlign: 'center', margin: 50 }}><Spin size="large" /></div> : <>
            <Row gutter={4} style={{ backgroundColor: '#CCC', padding: '0 5px 5px 5px', borderRadius: 10 }}>
                <Col span={8}>
                    <Card bordered={false} title="Meter Info" style={{ marginTop: 5 }}>
                        <table className="meter-overview-info">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <a target="_blank" href={`https://ppex.us/?meter-number=${info.meter.meterNumber}`}
                                            rel="noreferrer">Sneak Peek</a>
                                        &nbsp;<CopyOutlined
                                            style={{ marginLeft: 10 }}
                                            title="Copy to clipboard"
                                            onClick={() => copySneakPeekUrl(info)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Meter number</th>
                                    <td>{info?.meter.meterNumber}</td>
                                </tr>
                                <tr>
                                    <th>Current period usage</th>
                                    <td>???</td>
                                </tr>
                                <tr>
                                    <th>SGC</th>
                                    <td>{info?.meter.sgc}</td>
                                </tr>
                                <tr>
                                    <th>TI</th>
                                    <td>{info?.meter.ti}</td>
                                </tr>
                                <tr>
                                    <th>KRN</th>
                                    <td>{info?.meter.krn}</td>
                                </tr>
                                <tr>
                                    <th>TCT</th>
                                    <td>{info?.meter.tct}</td>
                                </tr>
                                <tr>
                                    <th>EA</th>
                                    <td>{info?.meter.ea}</td>
                                </tr>
                                <tr>
                                    <th>Subclass</th>
                                    <td>{info?.meter.subClass}</td>
                                </tr>
                                <tr>
                                    <th>Active</th>
                                    <td>
                                        {/* {info?.meter.status === 'A' ? 'Active' : 'Inactive'} */}
                                        <Switch checked={info?.meter.status === 'A'} onChange={(checked) => onActiveToggle(checked, 'accounts.meters', info?.meter.id)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Date created</th>
                                    <td>{toShortDate(info?.meter.dateCreated)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <Card title="Actions" bordered={false} style={{ marginTop: 5 }}>
                        <div className="token-buttons">
                            <h3 style={{ margin: 1, padding: 0 }}>Generate Token</h3>
                            <Popconfirm onConfirm={genTestToken}
                                title={<>Are you sure you want to<br />generate a 0.1 unit token?</>}
                                okText="Yes" cancelText="No">
                                <Button type="primary" style={{ marginRight: 5 }}>Test</Button>
                            </Popconfirm>
                            <Popconfirm onConfirm={genClearTamper}
                                title={<>Are you sure you want to<br />generate a tamper token?</>} okText="Yes"
                                cancelText="No">
                                <Button type="primary" style={{ marginRight: 5 }}>Clear Tamper</Button>
                            </Popconfirm>
                            <Popconfirm onConfirm={genClearCredit}
                                title={<>Are you sure you want to<br />generate a clear credit token?</>}
                                okText="Yes" cancelText="No">
                                <Button type="primary" style={{ marginRight: 5 }}>Clear Credit</Button>
                            </Popconfirm>
                            {generatedTokens && generatedTokens.map((token, index) => (
                                <div key={index} style={{ marginTop: 5 }}>
                                    {token.type}: <Token token={token} />
                                </div>
                            ))}
                            <h3 style={{ margin: '10px 0 0 0', padding: 0 }}>Link</h3>
                            <div style={{ marginTop: 10 }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Input /></td>
                                            <td><Button type="primary">Link Jira Ticket</Button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false} title="Propery and Connection Info" style={{ marginTop: 5 }}>
                        <table className="meter-overview-info">
                            <tbody>
                                <tr>
                                    <th>Billing group</th>
                                    <td>{info?.billingGroup.name}</td>
                                </tr>
                                <tr>
                                    <th>Billing entity</th>
                                    <td>{info?.billingEntity.name}</td>
                                </tr>
                                <tr>
                                    <th>Account number</th>
                                    <td>
                                        <EditableValue
                                            prop={{
                                                id: info?.account.id,
                                                type: 'uuid',
                                                name: 'Account number',
                                                entity: 'accounts.accounts',
                                                field: 'account_number',
                                                value: info?.account.accountNumber
                                            }} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Active</th>
                                    <td>
                                        <Switch checked={info?.account.status === 'A'} onChange={(checked) => onActiveToggle(checked, 'accounts.accounts', info?.account.id)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>
                                        <span className="address-line">{info?.property.unit}</span>
                                        {info?.property.address.split(',').map((line, index) => (
                                            <span key={index} className="address-line">{line}</span>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <th>ERF number</th>
                                    <td>
                                        <EditableValue
                                            prop={{
                                                id: info?.property.id,
                                                type: 'uuid',
                                                name: 'ERF number',
                                                entity: 'accounts.properties',
                                                field: 'erf_number',
                                                value: info?.property.erfNumber || "-"
                                            }} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Munic acc number</th>
                                    <td>
                                        <EditableValue prop={{
                                            id: info?.property.id,
                                            type: 'uuid',
                                            name: 'Municipal account number',
                                            entity: 'accounts.properties',
                                            field: 'munic_number',
                                            value: info?.property.municipalAccountNumber || "-"
                                        }} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <Card title="Charge Group" bordered={false} style={{ marginTop: 5 }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Step</th>
                                    <th>Limit</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {info?.chargeGroup.steps.map((step, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={{ width: 80, textAlign: 'right' }}>{step.limit.toFixed(2)}</td>
                                        <td style={{ width: 80, textAlign: 'right' }}>{step.rate.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                    <Card bordered={false} title="Product Info" style={{ marginTop: 5 }}>
                        <table className="meter-overview-info">
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{info?.product.name}</td>
                                </tr>
                                <tr>
                                    <th>Unit</th>
                                    <td>{info?.product.unit}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </Col>
                <Col span={8}>
                    <NoticesWidget info={info} onInfoRefresh={refreshInfo} />
                    <PendingCollectionsWidget info={info} onInfoRefresh={refreshInfo} />
                    <RepeatedCollectionsWidget info={info} />
                    {/* <QuickAddCollectionWidget info={info} onInfoRefresh={refreshInfo} /> */}
                </Col>
            </Row>
            <IncidentMeterAddWidget info={info} onInfoRefresh={refreshInfo} />
            <div className="meter-history">
                <h3>Meter History (Vends Only <Switch checked={vendsOnlyHistory}
                    onChange={(checked) => setVendsOnlyHistory(checked)} />)</h3>
                {info.eventLogEntries.length === 0 ?
                    <div>No history</div> :
                    <Timeline mode='left'
                        items={info.eventLogEntries
                            .filter((entry) => !vendsOnlyHistory || (entry.event === "vending.vend-result"))
                            .map((entry) => {
                                return {
                                    label: toDisplayDate(entry.entryDate),
                                    children: getHistoryItemComponent(entry)
                                };
                            })} />

                }
            </div>
        </>
        }
    </>

}