import {IOverviewHistoryItemPropType} from "./overview-history-types";
import Token from "../../../../components/token";

interface IStsCreditHistoryItemData {
    is_error: boolean
    message: string
    meter_id: string
    meter_number: string
    token: string
    units_requested: number
}

export default function StsCreditHistoryItem({entry}: IOverviewHistoryItemPropType) {
    const item: IStsCreditHistoryItemData = entry.eventData;

    return <div>
        <table>
            <tbody>
            <tr>
                <th>Credit Token:</th>
                <th>Units</th>
                <td>{item.units_requested.toFixed(1)}</td>
                <th>Token</th>
                <td><Token token={{
                    token: item.token,
                    type: 'sts_credit',
                    units: item.units_requested.toFixed(1),
                }}/></td>
            </tr>
            </tbody>
        </table>
    </div>;
}