import { Card, Col, Row, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import SalesChart from "./home/sales-chart";
import CollectionRecoveryChart from "./home/collection-recovery-chart";

function HomePage() {


    return (
        <>
            <h1>Reign Utilities Overview</h1>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                        <SalesChart />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <CollectionRecoveryChart />
                    </Card>
                </Col>
                {/*<Col span={6}>*/}
                {/*    <Card bordered={false}>*/}
                {/*        <Statistic*/}
                {/*            title="Active"*/}
                {/*            value={11.28}*/}
                {/*            precision={2}*/}
                {/*            valueStyle={{ color: '#3f8600' }}*/}
                {/*            // prefix={<ArrowUpOutlined />}*/}
                {/*            suffix="%"*/}
                {/*        />*/}
                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </>
    );
}

export default HomePage;