import ReactECharts from "components/react-chart";
const labelRight = {
    position: 'right'
};
const option = {
    color: ['#347564'],
    title: {
        text: 'Over/Under Collection Recovery',
    },
    tooltip: {},
    xAxis: {
        type: 'value',
        position: 'top',
        data: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        splitLine: {
            lineStyle: {
                type: 'dashed'
            }
        }
    },
    grid: {
        right: '20%'
    },
    yAxis: {
        type: 'category',
        axisLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        data: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
    },
    series: [
        {
            name: 'Water',
            type: 'bar',
            label: {
                show: true,
                formatter: '{b}'
            },
            data: [
                { value: -310, label: labelRight},
                { value: 220, label: labelRight},
                { value: 430, label: labelRight},
                { value: -540, label: labelRight},
                { value: 50, label: labelRight},
                { value: -60, label: labelRight},
            ],
        },
    ],
};

export default function CollectionRecoveryChart() {

    return (
        <ReactECharts option={option} style={{height: 400}} />
    );
}
