import {Button} from "antd";
import {useState} from "react";
import NewIncident from "../../../../components/new-incident";

interface IncidentMeterAddWidgetProps {
    info: any;
    onInfoRefresh: () => void;
}


export default function IncidentMeterAddWidget({info, onInfoRefresh}: IncidentMeterAddWidgetProps) {

    const [showForm, setShowForm] = useState(false);

    return <>
        {!showForm && <div style={{textAlign: 'center', marginTop: 15}}>
            <Button type="primary" onClick={() => setShowForm(true)}>Create New Incident/Call Log Entry for this
                Meter</Button>
        </div>}
        {showForm &&
            <div style={{marginTop: 15, padding: '0 20px 20px 20px', border: '1px solid #ccc', borderRadius: 10}}>
                <NewIncident/>
            </div>}

    </>
}