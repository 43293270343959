import ReactECharts from "components/react-chart";
const option = {
    color: ['#347564'],
    title: {
        text: 'Monthly Water Recoveries',
    },
    tooltip: {},
    xAxis: {
        data: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        axisTick: {
            alignWithLabel: true
        },
    },
    grid: {
        right: '20%'
    },
    yAxis: [
        {
            name: "Water",
            type: 'value',
            position: 'right',
            alignTicks: true,
            axisLabel: {
                formatter: '{value} kL'
            }
        },
    ],
    series: [
        {
            name: 'Water',
            type: 'bar',
            data: [2310, 2120, 4330, 2640, 2950, 1960],
        },
    ],
};

export default function SalesChart() {

    return (
        <ReactECharts option={option} style={{height: 400}} />
    );
}
