import { Form, Input, Space } from "antd";
import SubLabel from "components/sub-label";
import { IKctOptions } from "./token-gen-info";

interface IKctOptionsProps {
    options: IKctOptions;
    onChange: (options: IKctOptions) => void;
}

export default function KctOptions(props: IKctOptionsProps) {
    /*
        get input from user for 
        1. sgc
        2. krn
        3. tariff index
    */
    return (
        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
            <Form.Item label="SGC">
                <SubLabel label="Supply Group Code" />
                <Input
                    value={props.options.sgc}
                    onChange={(e) =>
                        props.onChange({
                            ...props.options,
                            sgc: e.target.value,
                        })
                    }
                />
            </Form.Item>
            <Form.Item label="KRN">
                <SubLabel label="Key Revision" />
                <Input
                    value={props.options.krn}
                    onChange={(e) =>
                        props.onChange({
                            ...props.options,
                            krn: e.target.value,
                        })
                    }
                />
            </Form.Item>
            <Form.Item label="TI">
                <SubLabel label="Tariff Index" />
                <Input
                    value={props.options.ti}
                    onChange={(e) =>
                        props.onChange({
                            ...props.options,
                            ti: e.target.value,
                        })
                    }
                />
            </Form.Item>
        </Space>
    );
}
